<template>
  <div class="chats">
    <div class="chats-header">
      <Button
        text="New chat"
        icon="plus-medium"
        type="grey"
        :full-width="true"
        @click="$emit('add')"
      />
    </div>
    <div class="chats-list">
      <div
        v-for="chat in chats"
        :key="chat.uuid"
        class="chats-list-item"
        :class="{
          current:
            chat.uuid === $route.params.chat_id ||
            (!$route.params.chat_id && chat.uuid === '')
        }"
        :title="chat.title"
        @click="$emit('load', chat.uuid)"
      >
        <p class="chats-list-item-name">{{ chat.title }}</p>
        <div class="chats-list-item-remove" :class="{ newchat: !chat.uuid }">
          <b-tooltip
            label="Delete chat"
            position="is-top"
            type="is-dark"
            append-to-body
          >
            <Button
              icon="bin"
              type="grey"
              size="xs"
              @click.native.stop="$emit('delete', chat)"
            />
          </b-tooltip>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from '@c/library/Button.vue'

export default {
  name: 'ChatSidebar',
  components: { Button },
  props: {
    chats: {
      type: Array,
      default: () => []
    }
  }
}
</script>

<style scoped lang="scss">
.chats {
  overflow-y: auto;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  border-right: 1px solid rgba(#000, 0.08);

  &-header {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-evenly;
    gap: 0.5rem;
    width: 100%;
    padding: 1rem;

    &-btns {
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      gap: 0.5rem;

      &-item {
        cursor: pointer;
      }
    }
  }

  &-list {
    display: flex;
    flex-flow: column-reverse nowrap;
    width: 100%;

    &-item {
      padding: 1rem 1.5rem;
      width: 100%;
      max-width: 100%;
      overflow: hidden;
      cursor: pointer;
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      align-items: center;
      background: white;
      transition: background 0.2s ease-in-out;

      &.current {
        background: rgba(#000, 4%);
      }

      &:hover {
        background: rgba(#000, 8%);
      }

      &-name {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      &-remove {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        gap: 0.5rem;

        &.newchat {
          opacity: 0;
          pointer-events: none;
        }
      }
    }
  }
}
</style>
