<template>
  <div class="sources-cards-wrapper">
    <div class="sources-cards">
      <div
        v-for="el in visibleSources"
        :key="el.uuid"
        class="sources-cards-card"
      >
        <a :href="cardRoute(el)" target="_blank">
          <component
            :is="cardComponent[el.type]"
            :show-attributes="false"
            v-bind="cardProp(el.type, el)"
          />
        </a>
      </div>
    </div>
    <Button
      v-if="showShowMore"
      :text="showMore ? 'Show less' : showMoreText"
      type="light-solid"
      :full-width="true"
      @click="() => (showMore = !showMore)"
    />
  </div>
</template>

<script>
import Button from '@c/library/Button.vue'
import InspirationCard from '../inspirations/InspirationCard.vue'
import OfferingCard from '../offerings/OfferingCard.vue'
import ReferenceCard from '../references/ReferenceCard.vue'

export default {
  name: 'ChatMessageSources',
  components: { Button },
  props: {
    sources: {
      type: Array,
      required: true
    }
  },
  data: () => ({
    showMore: false
  }),
  computed: {
    cardComponent() {
      return {
        references: ReferenceCard,
        offerings: OfferingCard,
        inspirations: InspirationCard
      }
    },
    showShowMore() {
      return this.sources.length > 2
    },
    showMoreText() {
      const count = this.sources.length - 2
      return `+${count} more source${count === 1 ? '' : 's'}`
    },
    visibleSources() {
      return this.showMore ? this.sources : this.sources.slice(0, 2)
    }
  },
  methods: {
    cardProp(type, el) {
      return {
        [type.slice(0, -1)]: el
      }
    },
    cardRoute(el) {
      return this.$router.resolve({
        name: 'portfolio',
        params: {
          workspace_id: this.$route.params.workspace_id,
          ori_type: el.type,
          ori_id: el.uuid
        }
      }).href
    }
  }
}
</script>

<style lang="scss" scoped>
.sources {
  &-cards {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;

    &-card {
      cursor: pointer;
      transition: transform 0.2s ease;

      &:hover {
        transform: scale(1.02);
      }
    }

    &-wrapper {
      display: flex;
      flex-flow: column nowrap;
      gap: 0.5rem;
      padding-bottom: 1rem;
    }
  }
}
</style>
