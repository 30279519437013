var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "sources-cards-wrapper" },
    [
      _c(
        "div",
        { staticClass: "sources-cards" },
        _vm._l(_vm.visibleSources, function(el) {
          return _c(
            "div",
            { key: el.uuid, staticClass: "sources-cards-card" },
            [
              _c(
                "a",
                { attrs: { href: _vm.cardRoute(el), target: "_blank" } },
                [
                  _c(
                    _vm.cardComponent[el.type],
                    _vm._b(
                      { tag: "component", attrs: { "show-attributes": false } },
                      "component",
                      _vm.cardProp(el.type, el),
                      false
                    )
                  )
                ],
                1
              )
            ]
          )
        }),
        0
      ),
      _vm.showShowMore
        ? _c("Button", {
            attrs: {
              text: _vm.showMore ? "Show less" : _vm.showMoreText,
              type: "light-solid",
              "full-width": true
            },
            on: {
              click: function() {
                return (_vm.showMore = !_vm.showMore)
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }