var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "chat" }, [
    !_vm.chatStarted
      ? _c("div", { staticClass: "chat-new" }, [
          _c("p", { staticClass: "chat-new-header" }, [
            _vm._v(
              " PortfolioGPT helps you answer any questions on your offerings, cases and inspirational content in seconds "
            )
          ]),
          _vm._m(0),
          _c(
            "div",
            { staticClass: "chat-new-examples" },
            _vm._l(_vm.exampleQuestions, function(example, idx) {
              return _c(
                "div",
                {
                  key: idx,
                  staticClass: "chat-new-examples-item",
                  on: {
                    click: function($event) {
                      return _vm.handleExampleClick(example)
                    }
                  }
                },
                [_vm._v(' "' + _vm._s(example) + '" ')]
              )
            }),
            0
          )
        ])
      : _vm.historyLoading
      ? _c(
          "div",
          { staticClass: "chat-loading" },
          [_c("b-loading", { attrs: { "is-full-page": false, active: "" } })],
          1
        )
      : _c(
          "ul",
          { ref: "chatlog", staticClass: "chat-log" },
          [
            _vm.answerLoading
              ? _c(
                  "li",
                  { staticClass: "chat-log-item answer" },
                  [
                    _c("img", {
                      staticClass: "chat-log-item-avatar",
                      attrs: { src: _vm.avatarByAuthor.bot, alt: "" }
                    }),
                    _c("LoadingDots", {
                      staticClass: "chat-log-item-loading",
                      attrs: { color: "white" }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _vm._l(_vm.history, function(item, idx) {
              return _c(
                "li",
                {
                  key: item.author + "-" + idx,
                  staticClass: "chat-log-item",
                  class: {
                    question: item.author.toLowerCase() === "user",
                    answer: item.author.toLowerCase() === "bot"
                  }
                },
                [
                  _vm.avatarByAuthor[item.author.toLowerCase()] &&
                  !_vm.avatarErrored
                    ? _c("img", {
                        staticClass: "chat-log-item-avatar",
                        attrs: {
                          src: _vm.avatarByAuthor[item.author.toLowerCase()],
                          alt: ""
                        },
                        on: {
                          error: function($event) {
                            _vm.avatarErrored = true
                          }
                        }
                      })
                    : _c(
                        "div",
                        {
                          staticClass: "chat-log-item-avatar",
                          style: {
                            background: _vm.$umodel.user_color(_vm.currentUser)
                          }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$umodel.initials(_vm.currentUser)) +
                              " "
                          )
                        ]
                      ),
                  item.author.toLowerCase() === "user"
                    ? _c("span", { staticClass: "chat-log-item-text" }, [
                        _vm._v(_vm._s(item.content))
                      ])
                    : item.author.toLowerCase() === "bot"
                    ? _c(
                        "div",
                        { staticClass: "chat-log-item-text" },
                        [
                          _c("MarkdownEdit", {
                            attrs: { value: item.content }
                          }),
                          item.sources && item.sources.length
                            ? _c("ChatMessageSources", {
                                attrs: { sources: item.sources }
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    : _vm._e(),
                  _c("span", { staticClass: "chat-log-item-timestamp" }, [
                    _vm._v(_vm._s(_vm.formatDateWTime(item.timestamp)))
                  ])
                ]
              )
            })
          ],
          2
        ),
    _c("div", { staticClass: "chat-input" }, [
      _c("span", {
        ref: "questioninput",
        staticClass: "chat-input-input is-textarea",
        attrs: {
          role: "textbox",
          contenteditable: !(
            _vm.answerLoading ||
            _vm.answerTyping ||
            _vm.historyLoading
          ),
          placeholder:
            "Ask anything about your offerings, cases or inspirational content..."
        },
        on: {
          input: _vm.handleQuestionInput,
          paste: function($event) {
            $event.preventDefault()
            return _vm.handleQuestionPaste.apply(null, arguments)
          },
          keydown: function($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
            ) {
              return null
            }
            return _vm.handleEnter.apply(null, arguments)
          }
        }
      }),
      _c("img", {
        staticClass: "chat-input-icon",
        attrs: { src: require("@/assets/icons/send.svg"), alt: "" },
        on: { click: _vm.sendMessage }
      })
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "chat-new-subheader" }, [
      _c("img", {
        staticClass: "chat-new-subheader-icon",
        attrs: { src: require("@/assets/icons/ai.svg"), alt: "" }
      }),
      _vm._v(" Try these examples ")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }