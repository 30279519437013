<template>
  <div ref="chat" class="chat-wrapper" :style="maxHeightStyle">
    <ChatSidebar
      :chats="chats"
      class="chat-wrapper-sidebar"
      @load="loadChat"
      @add="newChat"
      @delete="deleteChat"
    />
    <ChatInterface
      v-if="currentChat !== 'none'"
      :key="chatInterfaceKey"
      :chat-uuid="currentChat"
      @update="handleUpdateCurrent"
    />
  </div>
</template>

<script>
import { deleteChat, getChats } from '@/services/chatService'
import { mapActions, mapGetters } from 'vuex'
import ChatInterface from './chat/ChatInterface.vue'
import ChatSidebar from './chat/ChatSidebar.vue'
import { MaxHeightMixin } from '@/mixins/MaxHeightMixin'

export default {
  name: 'Chat',
  mixins: [MaxHeightMixin],
  components: { ChatInterface, ChatSidebar },
  data: () => ({
    chatHeight: 0,
    currentChat: 'none',
    chats: [],
    chatInterfaceKey: new Date().getTime()
  }),
  computed: {
    ...mapGetters(['currentWorkspace', 'canWriteChats'])
  },
  mounted() {
    this.checkCurrentWorkspace()
    if (!this.canWriteChats) {
      this.$router.push({ name: 'home' })
    }
    this.setMaxHeight()
    window.addEventListener('resize', this.setMaxHeight)
    this.loadChats()
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.setMaxHeight)
  },
  methods: {
    ...mapActions(['setCurrentWorkspace']),
    getMaxHeightElement() {
      return this.$refs.chat
    },
    checkCurrentWorkspace() {
      if (
        !this.currentWorkspace ||
        this.currentWorkspace.uuid !== this.$route.params.workspace_id
      ) {
        this.setCurrentWorkspace(this.$route.params.workspace_id)
      }
    },
    setMaxHeight() {
      const chat = this.$refs.chat.getBoundingClientRect()
      const body = document.body.getBoundingClientRect()
      this.chatHeight = body.height - (chat.top - body.top)
    },
    async loadChats() {
      try {
        const data = await getChats({
          workspace_id: this.$route.params.workspace_id,
          act_as: this.$route.query.act_as
        })
        this.chats = data?.results || []
      } catch (e) {
        this.$console.debug('Chats retrieval failed', e)
        this.$toast.error(e, 'loading chat history')
      } finally {
        this.checkCurrentChat()
      }
    },
    checkCurrentChat() {
      if (!this.$route.params.chat_id) this.newChat()
      else if (this.$route.params.chat_id !== this.currentChat)
        this.loadChat(this.$route.params.chat_id)
    },
    loadChat(chat) {
      this.currentChat = chat
      if (this.$route.params.chat_id !== (chat || undefined))
        this.$router.push({
          name: 'chat',
          params: { chat_id: chat || undefined }
        })
      this.chatInterfaceKey = new Date().getTime()
    },
    newChat() {
      if (this.chats.findIndex((chat) => chat.uuid === '') !== -1) {
        this.loadChat('')
        return
      }
      this.chats.push({
        uuid: '',
        title: 'New Chat'
      })
      this.loadChat(this.chats[this.chats.length - 1].uuid)
    },
    async deleteChat(chat) {
      try {
        if (!chat.uuid) {
          this.chats = this.chats.filter((c) => c.uuid !== '')
          if (!this.chats.length) {
            this.currentChat = 'none'
            this.$router.push({ name: 'chat', params: { chat_id: undefined } })
          }
          return
        }
        await deleteChat({
          workspace_id: this.$route.params.workspace_id,
          chat_id: chat.uuid,
          act_as: this.$route.query.act_as
        })
        this.chats = this.chats.filter((c) => c.uuid !== chat.uuid)
        this.newChat()
      } catch (e) {
        if (e === 'Not found.') {
          this.chats = this.chats.filter((c) => c.uuid !== chat.uuid)
          this.newChat()
          return
        }
        this.$console.debug('Chat deletion failed', e)
        this.$toast.error(e, 'deleting chat')
      }
    },
    handleUpdateCurrent({ uuid, title }) {
      const idx = this.chats.findIndex((chat) => chat.uuid === this.currentChat)
      if (idx === -1) return
      this.chats[idx].uuid = uuid
      if (title) this.chats[idx].title = title
      this.currentChat = uuid
      this.$router.push({ name: 'chat', params: { chat_id: uuid } })
    }
  }
}
</script>

<style scoped lang="scss">
.chat-wrapper {
  display: grid;
  grid-template-columns: 1fr 4fr;
}
</style>
