var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "chat", staticClass: "chat-wrapper", style: _vm.maxHeightStyle },
    [
      _c("ChatSidebar", {
        staticClass: "chat-wrapper-sidebar",
        attrs: { chats: _vm.chats },
        on: { load: _vm.loadChat, add: _vm.newChat, delete: _vm.deleteChat }
      }),
      _vm.currentChat !== "none"
        ? _c("ChatInterface", {
            key: _vm.chatInterfaceKey,
            attrs: { "chat-uuid": _vm.currentChat },
            on: { update: _vm.handleUpdateCurrent }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }