var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "chats" }, [
    _c(
      "div",
      { staticClass: "chats-header" },
      [
        _c("Button", {
          attrs: {
            text: "New chat",
            icon: "plus-medium",
            type: "grey",
            "full-width": true
          },
          on: {
            click: function($event) {
              return _vm.$emit("add")
            }
          }
        })
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "chats-list" },
      _vm._l(_vm.chats, function(chat) {
        return _c(
          "div",
          {
            key: chat.uuid,
            staticClass: "chats-list-item",
            class: {
              current:
                chat.uuid === _vm.$route.params.chat_id ||
                (!_vm.$route.params.chat_id && chat.uuid === "")
            },
            attrs: { title: chat.title },
            on: {
              click: function($event) {
                return _vm.$emit("load", chat.uuid)
              }
            }
          },
          [
            _c("p", { staticClass: "chats-list-item-name" }, [
              _vm._v(_vm._s(chat.title))
            ]),
            _c(
              "div",
              {
                staticClass: "chats-list-item-remove",
                class: { newchat: !chat.uuid }
              },
              [
                _c(
                  "b-tooltip",
                  {
                    attrs: {
                      label: "Delete chat",
                      position: "is-top",
                      type: "is-dark",
                      "append-to-body": ""
                    }
                  },
                  [
                    _c("Button", {
                      attrs: { icon: "bin", type: "grey", size: "xs" },
                      nativeOn: {
                        click: function($event) {
                          $event.stopPropagation()
                          return _vm.$emit("delete", chat)
                        }
                      }
                    })
                  ],
                  1
                )
              ],
              1
            )
          ]
        )
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }